import { useState } from 'react';
import { Stack } from '@mui/material';
import Main from './components/main';
import Footer from './components/footer';

export default function Login() {
  const [showFooter, setShowFooter] = useState(true);

  return (
    <Stack
      sx={{
        height: 1,
      }}
    >
      <Stack
        sx={{
          py: 2,
          height: '100%',
          maxWidth: '480px',
          minWidth: { md: '480px' },
          margin: 'auto',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Main
          onShowForm={(show) => {
            setShowFooter(show);
          }}
        />
        {showFooter ? <Footer /> : null}
      </Stack>
    </Stack>
  );
}
