import { Stack, Typography, Link } from '@mui/material';
import { useLocales } from '@core/i18n';
import { ROUTES } from '@core/config';

export default function Footer() {
  const { translate } = useLocales();

  return (
    <Stack
      sx={{ width: 1, p: 2 }}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <Typography variant="caption" color="text.secondary">
        {translate('login.disclaimer.part1')}
        <Link
          href={ROUTES.termsOfUse}
          target="_blank"
          sx={{
            color: 'text.secondary',
            textDecoration: 'underline',
          }}
        >
          {translate('login.disclaimer.termsOfUse')}
        </Link>
        {translate('login.disclaimer.and')}
        <Link
          href={ROUTES.privacyPolicy}
          target="_blank"
          sx={{
            color: 'text.secondary',
            textDecoration: 'underline',
          }}
        >
          {translate('login.disclaimer.privacyPolicy')}
        </Link>
      </Typography>
    </Stack>
  );
}
