import { useState } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { useLocales } from '@core/i18n';
import Form from './form';
import Logo from '@core/ui/components/logo/Logo';

interface Props {
  onShowForm: (show: boolean) => void;
}

export default function Main({ onShowForm }: Props) {
  const { translate } = useLocales();

  const [successEmail, setSuccessEmail] = useState('');

  return (
    <Stack
      spacing={5}
      sx={{
        width: 1,
        height: 1,
        px: 2,
        pt: { sm: 4, md: 8 },
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Logo />

      <Stack justifyContent={'center'} alignItems="center">
        <Typography variant="h3">
          {successEmail
            ? translate('login.success.title')
            : translate('login.title')}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          {successEmail
            ? translate('login.success.subtitle', {
                email: successEmail,
              })
            : translate('global.bowldOneLiner')}
        </Typography>
      </Stack>

      {successEmail ? (
        <Button
          onClick={() => {
            setSuccessEmail('');
            onShowForm(true);
          }}
        >
          {translate('global.back')}
        </Button>
      ) : (
        <Form
          onFinish={(email) => {
            setSuccessEmail(email);
            onShowForm(false);
          }}
        />
      )}
    </Stack>
  );
}
